const redirects = {
   'url1' : { landing_page : 'https://creddo.com/', name: "Creddo" },
   'url2' : { landing_page : 'https://creddo.com/se/#faq', name: "Creddo Q&A" },
   'url3' : { landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=EU_Loan_FEB24+', name: "Creddo homeplage" },
   'url4' : { landing_page : 'https://creddo.com/se/foretagslan/eu-lanet/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=EU_Loan_FEB24+', name: "Creddo EU-lånet" },
   'url5' : { landing_page : 'https://creddo.com/se/foretagslan/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=EU_Loan_FEB24+', name: "Creddo Företagslån" },

   'url6' : { landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=EU_Loan_MAR06+', name: "Creddo homeplage" },
   'url7' : { landing_page : 'https://creddo.com/se/foretagslan/eu-lanet/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=EU_Loan_MAR06+', name: "Creddo EU-lånet" },
   'url8' : { landing_page : 'https://creddo.com/se/foretagslan/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=EU_Loan_MAR06+', name: "Creddo Företagslån" },

   'url9' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_March24',
      name: 'Toppbild - v1'
   },

   'url10': {
      landing_page: 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_March24',
      name: 'Apply button - v1'
   },

   'url11': {
      landing_page: 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_March24',
      name: 'Trustpilot - v1'
   },

   'url12' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_March24',
      name: 'Toppbild logo - v1'
   },

   'url13' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_March24',
      name: 'Toppbild - v2'
   },

   'url14': {
      landing_page: 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_March24',
      name: 'Apply button - v2'
   },

   'url15': {
      landing_page: 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_March24',
      name: 'Trustpilot - v2'
   },

   'url16' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_March24',
      name: 'Toppbild logo - v2'
   },

   'url17' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_April24',
      name: 'Toppbild - v1 - 24-04'
   },
   'url18' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_April24',
      name: 'Toppbanner - v1 - 24-04'
   },
   'url19' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_April24',
      name: 'Button - Ansök nu - 24-04'
   },
   'url20' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_April24',
      name: 'Trustpilot - 24-04'
   },


   'url21' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_April24',
      name: 'Toppbild - v2 - 24-04'
   },
   'url22' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_April24',
      name: 'Button - v2 - Ansök nu - 24-04'
   },
   'url23' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_April24',
      name: 'Trustpilot - v2 - 24-04'
   },
   
   'url24' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_April24',
      name: 'Trustpilot - v2 - 24-04'
   },


   'url25' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_May24',
      name: 'Bild - Toppbild'
   },

   'url26' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_May24',
      name: 'Link - Här kan du'
   },

   'url27' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_May24',
      name: 'Link - Påbörja ansökan'
   },

   'url28' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_May24',
      name: 'Knapp - Ansök nu'
   },

   'url29' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=General_May24',
      name: 'Trustpilot - v2 - 24-04'
   },

   'url30' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=Refinancing_Sep24',
      name: 'Toppbild - Refinancing_Sep24'
   },
   
   'url31' : {
      landing_page : 'https://creddo.com/se/?utm_source=Reaktion.se&utm_medium=Email&utm_campaign=Refinancing_Sep24',
      name: 'Ansök om refinansiering - Refinancing_Sep24'
   },

   

};

export default redirects